export const NikkeSpecialitiesArray = [
  { label: 'Any Speciality', value: 'all', description: '' },
  {
    label: 'Buff Time Alteration',
    value: 'Buff Time Alteration',
    description: '-'
  },
  {
    label: 'Buffer',
    value: 'Buffer',
    description: 'Supports allies with positive effects.'
  },
  {
    label: 'Burst CD Reduction',
    value: 'Burst CD Reduction',
    description: '-'
  },
  {
    label: 'Cleanser',
    value: 'Cleanser',
    description: 'Removes debuffs from allies.'
  },
  {
    label: 'Cover Heal',
    value: 'Cover Heal',
    description: '-'
  },
  {
    label: 'Crowd Controller',
    value: 'Crowd Controller',
    description: 'Controls the battlefield for example by Stunning enemies.'
  },
  {
    label: 'Debuffer',
    value: 'Debuffer',
    description: 'Hinders enemies with negative effects.'
  },
  {
    label: 'Decoy',
    value: 'Decoy',
    description: '-'
  },
  {
    label: 'Healer',
    value: 'Healer',
    description: 'Keeps allies alive.'
  },
  {
    label: 'Re-Enter Burst Stage',
    value: 'Re-Enter Burst Stage',
    description: '-'
  },
  {
    label: 'Revive',
    value: 'Revive',
    description: '-'
  },
  {
    label: 'Screen Wipe',
    value: 'Screen Wipe',
    description: '-'
  },
  {
    label: 'Shielder',
    value: 'Shielder',
    description: 'Supports allies with shields.'
  },
  {
    label: 'Stack Oriented',
    value: 'Stack Oriented',
    description: '-'
  },
  {
    label: 'Taunter',
    value: 'Taunter',
    description: 'Forces enemies to attack her.'
  },
  {
    label: 'True Damage',
    value: 'True Damage',
    description: 'Deals damage that bypasses Def.'
  }
];
